import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import Layout from "gatsby-theme-blog/src/components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      placeholderImage: file(relativePath: { eq: "lacey-lost.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout location={{ pathname: "404" }} title={data.site.siteMetadata.title}>
      <SEO title="404: Not found" />
      <h1>WOOF!</h1>
      <p>Looks like Lacey isn't gonig to let you stop. How about some pets?</p>
      <Img fluid={data.placeholderImage.childImageSharp.fluid} />
    </Layout>
  );
}

export default NotFoundPage
